var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('b-alert', {
    ref: "alert",
    attrs: {
      "show": _vm.controls.alert.show,
      "dismissible": "",
      "fade": "",
      "variant": "warning"
    },
    on: {
      "dismissed": function dismissed($event) {
        _vm.controls.alert.show = 0;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.controls.alert.message) + " ")]), _c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Personal information"
    }
  }, [_c('user-details-form', {
    ref: "detailsForm",
    attrs: {
      "active": _vm.activeTab === 0,
      "id": _vm.userId,
      "mode": _vm.mode
    },
    on: {
      "message": _vm.showMessage,
      "toast": _vm.makeToast
    }
  })], 1), _vm.userId ? _c('b-tab', {
    attrs: {
      "title": "Salary"
    }
  }, [_c('user-salary-form', {
    ref: "salaryForm",
    attrs: {
      "active": _vm.activeTab === 1,
      "id": _vm.userId,
      "mode": _vm.mode
    },
    on: {
      "message": _vm.showMessage,
      "toast": _vm.makeToast
    }
  })], 1) : _vm._e(), _vm.userId ? _c('b-tab', {
    attrs: {
      "title": "Benefits"
    }
  }, [_c('user-benefits', {
    ref: "benefits",
    attrs: {
      "active": _vm.activeTab === 2,
      "id": _vm.userId,
      "mode": _vm.mode
    },
    on: {
      "message": _vm.showMessage,
      "toast": _vm.makeToast
    }
  })], 1) : _vm._e(), _vm.userId ? _c('b-tab', {
    staticStyle: {
      "height": "95vh",
      "overflow": "auto"
    },
    attrs: {
      "title": "Permissions"
    }
  }, [_c('user-permissions-form', {
    ref: "permissionsForm",
    attrs: {
      "active": _vm.activeTab === 3,
      "id": _vm.userId,
      "mode": _vm.mode,
      "is-embeded": false
    },
    on: {
      "message": _vm.showMessage,
      "toast": _vm.makeToast
    }
  })], 1) : _vm._e(), _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.activities.title
    }
  }, [_c('h5', [_vm._v("User's sales activities")]), _c('hr'), _c('activities-table', {
    attrs: {
      "user-id": _vm.id,
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onActivitiesLoad
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.reports.title
    }
  }, [_c('h5', [_vm._v("Reports submitted by user")]), _c('hr'), _c('reports-table', {
    ref: "reports-table",
    attrs: {
      "filter": "user",
      "show-add-button": false,
      "user-id": _vm.id
    },
    on: {
      "loaded": _vm.onReportsLoad
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.tasks.title
    }
  }, [_c('h5', [_vm._v("User's tasks for the last 90 days")]), _c('hr'), _c('tasks-table', {
    ref: "tasks",
    attrs: {
      "rows-per-page": 20,
      "autoload": true,
      "selectable-rows": false,
      "user-id": _vm.id,
      "filter-by-column": true
    },
    on: {
      "loaded": _vm.onTasksLoad
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.vacationRequests.title
    }
  }, [_c('h5', [_vm._v("User's vacation requests")]), _c('hr'), _c('vacation-requests-table', {
    ref: "vacationRequests",
    attrs: {
      "user-id": _vm.id
    },
    on: {
      "loaded": _vm.onVacationRequestsLoad
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.recentTrackTime.title
    }
  }, [_c('h5', [_vm._v("Time tracking for the last 30 days")]), _c('hr'), _c('recent-track-time-table', {
    ref: "recentTrackTime",
    attrs: {
      "user-id": _vm.id
    },
    on: {
      "loaded": _vm.onRecentTrackTimeLoad
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.activityTracking.title
    }
  }, [_c('h5', [_vm._v("Recent 299 activities in the CRM")]), _c('hr'), _c('user-activity-tracking-table', {
    ref: "userActivityTrackingTable",
    attrs: {
      "user-id": _vm.id
    },
    on: {
      "loaded": _vm.onActivityTrackingLoad
    }
  })], 1), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$constants.MODULES.Users.id,
      "entity-id": _vm.id,
      "show-category": true,
      "show-expiration-date": true
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e()], 1)], 1)]), _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "no-fade": "",
      "ok-only": "",
      "title": 'Message'
    }
  }, [_vm._v(" " + _vm._s(_vm.controls.modal.message) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }